import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/ux/banner1.jpg";
import ContrastSection from "../../../components/ContrastSection";

import { Container } from "reactstrap";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Img from "gatsby-image";

//Accordian
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import plus from "../../../images/icons/plus.svg";
import minus from "../../../images/icons/minus.svg";

//Images
import BannerUx from "../../../images/services/ux/banner-ux.jpg";
import BannerDesign from "../../../images/services/ux/banner-webdesign.jpg";
import BannerArchitect from "../../../images/services/ux/banner-architect.jpg";
import ConsultationBanner from "../../../images/consultation.jpg";

//Process
import OurProcess from "../../../components/services/OurProcess";

//Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";

const UxTechnicalDesign = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        keyword="UX Design"
        title="UX & Technical Design"
        description="Helping businesses improve usability, increase user engagement and enhance customer experience. Customer centric design of websites or platforms."
      />
      {/* <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">UX &amp; Technical Design</h1>
              <hr />
            </div>
          </div>
        </div>
      </div> */}
      <IntroBanner
        title="UX & Technical Design"
        subtitle="Our design experts know how to create for the now and the future"
        image={Banner}
      />
      {/* <ContrastSection
        title="Our design experts know how to create for the now and the future"
        para={["Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar. Lorem ipsum dolor sit amet consectoli tur adipiscing elit semper dalar."]}
      /> */}
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerUx} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">User Experience (UX) &amp; Design</h2>
            <p>
              <strong>Boost engagement and satisfaction with customer centric design.</strong>
              <br/>
              We'll work with you to improve usability, increase user engagement and enhance your customer experience.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img alt="product innovation" className="img-fluid" src={BannerDesign} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Web Design &amp; Content Management Services</h2>
            <p>
              <strong>A well-structured website is the cornerstone of success. </strong>
              <br/>
              We'll work with you to create a high-quality website that matches your needs, ensures ease of maintenance and maximises cost efficiency.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img alt="product innovation" className="img-fluid" src={BannerArchitect} />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Platform Architecture &amp; Design</h2>
            <p>
              <strong>Enable scalability and efficiency for your needs now and in the future.</strong>
              <br/>
              We'll help you build a flexible and scalable framework that meets today's functional requirements and allows for ease of future enhancements. Seamless integration, process efficiency and a robust foundation come as standard in the architecture and design work we do.
            </p>
          </div>
        </div>
        <div className="getting-touch"><GetInTouchbtn textdce={'Free consultation'} /></div>
      </div>
      <Container>
        <OurProcess />
      </Container>
      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "productinnovation-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default UxTechnicalDesign;
